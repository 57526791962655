.dnd {
  display: flex;
  flex-direction: row;
}

.dndList {
  margin: 1em;
  margin-left: 2em;
  border: 1px solid #ccc;
  min-width: 13em;
  min-height: 5em;
  display: inline-block;
  vertical-align: top;
  background-color: #efefef;
  font-size: 13px;
  max-height: 50vh;
  overflow-y: auto;
}
.dndList-header {
  padding: 0.6em 0.8em;
  overflow: auto;
  color: #efefef;
  background-color: #3c8dbc;
}
.dndList-name {
  float: left;
}
.dndList-add-dndItem {
  float: right;
  margin-left: 0.5em;
}
.dndItems {
  max-width: 15em;
  margin: 0.5em;
  padding-left: 0;
  list-style: none;
}
.dndItem {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 12px;
  display: inline-block;
  padding: 5px 8px;
  margin-bottom: 2px;
  /* margin: 0 5px; */
  border-radius: 12px;
}
.dndItem .fa {
  font-size: 10px;
  padding-right: 10px;
}
.dndItem:hover {
  box-shadow: 0 0 0.3em 0.03em rgba(0,0,0,0.7);
  transition: 0.6s;
}
.dndItem:hover .delete {
  visibility: visible;
}
.dndItem .delete {
  float: right;
  padding: 0;
  background-color: #fdfdfd;
  border: none;
  cursor: pointer;
  visibility: hidden;
}
