.Sidebar {
  position: fixed;
  width: 300px;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  /* background: #222d32;
  color: #b8c7ce; */
  font-size: 13px;
  transition: 0.5s;
  overflow-x: hidden;
  background-color: #F7F9FC;
  opacity: 1;
  z-index: 1;
  background-color: rgb(34, 45, 50);
}

.Sidebar a {
  color: #b8c7ce;
  text-decoration: none;
  /* transition: 0.3s; */
}

.Sidebar a:hover {
  color: #f1f1f1;
}

.SidebarHeader {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 16px;
  background: #367fa9;
  color: #fff;
  /* width: calc(100% - 40px); */
}

.SidebarHeader a {
  transition: 0.3s;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

.Search {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
}

.Search i {
  position: absolute;
  margin-left: 18px;
}

.Search input {
  width: 100%;
  border: none;
  box-sizing: border-box;
  /* border: 1px lightgrey solid; */
  border-radius: 18px;
  background-color: white;
  margin: 10px;
  padding: 8px 8px 8px 36px;
}

.FolderHierarchy {
  margin-left: 15px;
}

.FolderHierarchy details summary {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
  white-space: nowrap;
  font-weight: bold;
  height: 25px;
}

.FolderHierarchy ul {
  list-style: none;
  padding: 0;
  margin: 6px;
}

.FolderHierarchy li {
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 25px;
  padding: 0 8px 0 16px;
  transition: 0.1s;
  border-radius: 18px;
  cursor: pointer;
  width: calc(100% - 30px);
  overflow: hidden;
  white-space: nowrap;
}

.FolderHierarchy li:before {
  content: "\f041";
  /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  width: 1.3em;
  /* same as padding-left set on li */
  vertical-align: top;
  color: #b8c7ce;
}

.FolderHierarchy .fa {
  color: #b8c7ce;
}

.FolderHierarchy li a {
  width: 95%;
  color: #b8c7ce;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.FolderHierarchy li:hover {
  /* color: white; */
  /* background-color: rgba(34, 45, 50, 0.1); */
  background-color: rgba(255, 255, 255, 0.2);
}

.FolderHierarchy li:hover .FolderHierarchy li:before {
  color: #f1f1f1;
}

/* .FolderHierarchy li:hover a {
  color: unset;
} */
.active-highlight {
  color: white !important;
  /* background-color: rgba(34, 45, 50, 0.8); */
  background-color: rgba(255, 255, 255, 0.4);
  /* font-weight: bold; */
}

.active-highlight>a {
  color: white !important;
}

.active-highlight::before {
  color: white !important;
}

.active-highlight:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.mainsection-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: scroll;
  scroll-behavior: contain;
  transition-duration: 0.2s;
}

@media (max-width: 900px) {
  .Sidebar {
    z-index: 10000;
  }
  .SidebarHeader  {
    display: flex;
    justify-content: space-between;
  }
}