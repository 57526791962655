.data-table-container{
	width: 90%;
	margin: 5px auto;
}

.data-table-container h1{
	color: rgb(60, 141, 188);
	font-size: 1rem;
}

.data-table {
	width: 100%;
	border-collapse: collapse;
	border-radius: 10px;
	white-space: nowrap;
	box-shadow: 0px 10px 10px #ccc;
	overflow: hidden;
}

.data-table thead {
	background-color: rgb(60, 141, 188);
	color: rgb(255, 255, 255);
	text-align: left;
}

.data-table th,
.data-table td {
	padding: 0.8rem;
	min-width: 0;
	overflow: hidden;
	color: rgb(116, 116, 116);
	word-break: break-word;
}

.data-table th {
	color: rgb(255, 255, 255);
	padding: 0.4rem;
	font-size: 0.9rem;
}

.data-table td {
	border-top: 0.5px solid #ddd;
	white-space: normal;
	font-size: 0.9rem;
	padding: 0.3rem;
}

.data-table tbody tr:hover {
	background-color: rgb(237, 237, 237);
}

.action-icons>i {
	color: white;
	margin-left: 5px;
}

.data-table tbody tr:hover .action-icons>i {
	color: grey;
}

@media (max-width: 1800px) {
	.data-table-container{
		width: 90%;
		margin: 5px auto;
		margin-left: 80px;
	}
}

@media (max-width: 1500px) {
	.data-table-container{
		width: 90%;
		margin: 5px auto;
		margin-left: 110px;
	}
}

@media (max-width: 1300px) {
	.data-table-container{
		width: 80%;
		margin: 5px auto;
		margin-left: 150px;
	}
}
@media (max-width: 900px) {
	.data-table-container{
		width: 95%;
		margin: 5px auto;
		margin-left: 10px;
		overflow-x: auto;
	}
	.data-table td {
		white-space: nowrap;
	}

}