/* @import url('../../node_modules/normalize.css/normalize.css');
@import url('../../node_modules/animate.css/animate.css');
@import url('../../node_modules/toastr/build/toastr.css');
@import url('../../node_modules/react-spinner/react-spinner.css');
@import url('../../node_modules/font-awesome/css/font-awesome.css');
@import url('../../node_modules/leaflet-fullscreen/dist/leaflet.fullscreen.css'); */

@import url('./tabs.css');

html {
    height: 100%;
}

body {
    background-color: #F7F9FC;
    /* background-color: #ecf0f5; */
    /*min-height: 100%;*/
}

#Analysis-Map {
    position: relative;
}

.MainSection {
    /* margin-left: 260px; */
    height: 100vh;
    transition: 0.5s;
}

.MainSection .row {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}

.MainSection .sidebar {
    width: 24%;
}

.MainSection .sidebar ul li {
    list-style: none;
    font-size: 14px;
    padding: 2px;
}

.MainSection .sidebar ul li a {
    text-decoration: none;
    color: #3c8dbc;
}

.MainSection .sidebar ul li a:hover {
    text-decoration: underline;
}

.MainSection .main {
    width: 74%;
}

.Header {
    background: #3c8dbc;
    color: #fff;
    padding: 0 16px;
    padding-left: 30px;
    height: 50px;
    font-weight: 300;
    display: flex;
    align-items: center;
}

.Header p {
    flex-grow: 1;
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Header .toggle {
    height: 25px;
    width: 25px;
    /* padding: 5px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    transition: 0.2s;
    cursor: pointer;
    text-decoration: none;
    padding: 0px;
    margin: 5px;
}

.Header .toggle:hover {
    padding: 5px;
    margin: unset;
    background: rgba(255, 255, 255, 0.2);
}

.Header .toggle:active:hover {
    background: rgba(255, 255, 255, 0.1);
    padding: 1px;
    margin: 4px;
}

.Header.normalPadding {
    margin-top: 20px;
    padding: 8px;
}

.lightBg {
    background-color: white;
}

.ContentSection {
    margin: 16px;
    margin-left: 50px;
    background: #fff;
    height: 100%;
    border-radius: 12px;
    /* border: 1px solid #ddd; */
    overflow: hidden;
}

.Content {
    padding: 16px;
    overflow: auto;
}

.fa {
    font-size: 18px;
    cursor: pointer;
    color: white;
    padding: 0 4px;
}

.white {
    color: #fff;
}

.Login {
    text-align: center;
}

.Login h3 {
    margin-top: 100px;
}

.Login .loginForm {
    background: #fff;
    margin: 10px auto;
    padding: 20px;
    display: inline-block;
    border-radius: 12px;
}

.Login .loginForm .Form .formBody .formInput input {
    width: 300px;
}

.react-spinner .react-spinner_bar {
    background-color: #333;
}

.react-spinner.white .react-spinner_bar {
    background-color: #fff;
}

.scaleModal {
    display: block;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 10000000000000000000;
    /* Sit on top */
    left: 0;
    top: 0;
    padding-left: 40%;
    padding-top: 15%;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.5);
    /* Fallback color */
}

.color-gradient-column {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.color-gradient-item {
    display: flex;
    flex-direction: row;
}

.color-gradient-item>i {
    width: 15px;
}

.color-gradient-item>span {
    font-size: 12px;
    color: #4c4c4c;
    position: absolute;
    margin-left: 20px;
}

.primary {
    background-color: #3c8dbc;
    color: white;
    border-color: transparent;
}

.primary>i {
    color: white;
}

.primary:hover {
    background-color: rgba(60, 141, 188, 0.8);
    /* border-color: #269abc; */
}

.pin:hover {
    color: indianred;
}

.pin-active {
    color: red;
}

.searchIcon {
    margin-right: 6px;
    z-index: 2;
    color: black;
}

.location-tree-div {
    overflow-y: auto;
}

.location-tree-node {
    border: 2px solid #3c8dbc;
    padding: 15px 20px 5px 20px;
    border-radius: 20px;
    font-weight: bold;
    color: #5d5d5d;
}

.location-roots-tab {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
}

.location-root {
    font-weight: bold;
    padding: 10px;
    border: 2px solid #367fa9;
    cursor: pointer;
}

.location-root-active {
    background-color: #269abc;
    color: white;
}

.location-tree-node>i {
    visibility: hidden;
}

.location-tree-node:hover>i {
    visibility: visible;
}

.icon-button {
    /* margin: 5px; */
    padding: 0;
    cursor: pointer;
    transition: box-shadow 0.1s;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    overflow: hidden;
    outline: none;
    border-radius: 100px;
}

.icon-button-primary {
    background-color: #3c8dbc;
}

.icon-button>i {
    color: gray;
    text-align: center;
    margin: auto;
    display: block;
}

.icon-button-primary>i {
    color: white;
}

.icon-button::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(209, 209, 209);
    opacity: 0;
    border-radius: 100px;
    transform: scale(0.5);
    transition: 0.2s;
}

.icon-button:hover::before {
    transform: scale(1);
    opacity: 0.2;
}

.icon-button:active::before {
    transform: scale(0.7);
    opacity: 0.4;
}

@media (max-width: 1800px) {
    .data-table-container {
        width: 85%;
        margin: 5px auto;
    }

    .ContentSection {
        margin: 16px;
        margin-left: 80px;
        background: #fff;
        height: 100%;
        border-radius: 12px;
        /* border: 1px solid #ddd; */
        overflow: hidden;
    }

    .Header {
        background: #3c8dbc;
        color: #fff;
        padding: 0 16px;
        padding-left: 80px;
        height: 50px;
        font-weight: 300;
        display: flex;
        align-items: center;
    }

    .Header p {
        flex-grow: 1;
        margin-left: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .Header .toggle {
        height: 25px;
        width: 25px;
        /* padding: 5px; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        transition: 0.2s;
        cursor: pointer;
        text-decoration: none;
        padding: 0px;
        margin: 5px;
    }

    .Header .toggle:hover {
        padding: 5px;
        margin: unset;
        background: rgba(255, 255, 255, 0.2);
    }

    .Header .toggle:active:hover {
        background: rgba(255, 255, 255, 0.1);
        padding: 1px;
        margin: 4px;
    }

    .Header.normalPadding {
        margin-top: 20px;
        padding: 8px;
    }
}

@media (max-width: 1400px) {
    .data-table-container {
        width: 70%;
        margin: 5px auto;
    }

    .ContentSection {
        margin: 16px;
        margin-left: 130px;
        background: #fff;
        height: 100%;
        border-radius: 12px;
        /* border: 1px solid #ddd; */
        overflow: hidden;
    }

    .Header {
        background: #3c8dbc;
        color: #fff;
        padding: 0 16px;
        padding-left: 120px;
        height: 50px;
        font-weight: 300;
        display: flex;
        align-items: center;
    }

    .Header p {
        flex-grow: 1;
        margin-left: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .Header .toggle {
        height: 25px;
        width: 25px;
        /* padding: 5px; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        transition: 0.2s;
        cursor: pointer;
        text-decoration: none;
        padding: 0px;
        margin: 5px;
    }

    .Header .toggle:hover {
        padding: 5px;
        margin: unset;
        background: rgba(255, 255, 255, 0.2);
    }

    .Header .toggle:active:hover {
        background: rgba(255, 255, 255, 0.1);
        padding: 1px;
        margin: 4px;
    }

    .Header.normalPadding {
        margin-top: 20px;
        padding: 8px;
    }
}

@media (max-width: 1150px) {
    .data-table-container {
        width: 70%;
        margin: 5px auto;
    }

    .ContentSection {
        margin: 16px;
        margin-left: 140px;
        background: #fff;
        height: 100%;
        border-radius: 12px;
        /* border: 1px solid #ddd; */
        overflow: hidden;
    }

    .Header {
        background: #3c8dbc;
        color: #fff;
        padding: 0 16px;
        padding-left: 140px;
        height: 50px;
        font-weight: 300;
        display: flex;
        align-items: center;
    }

    .Header p {
        flex-grow: 1;
        margin-left: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .Header .toggle {
        height: 25px;
        width: 25px;
        /* padding: 5px; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        transition: 0.2s;
        cursor: pointer;
        text-decoration: none;
        padding: 0px;
        margin: 5px;
    }

    .Header .toggle:hover {
        padding: 5px;
        margin: unset;
        background: rgba(255, 255, 255, 0.2);
    }

    .Header .toggle:active:hover {
        background: rgba(255, 255, 255, 0.1);
        padding: 1px;
        margin: 4px;
    }

    .Header.normalPadding {
        margin-top: 20px;
        padding: 8px;
    }
}

@media (max-width: 900px) {
    .ContentSection {
        margin: 16px;
        margin-left: 10px;
        background: #fff;
        height: 100%;
        border-radius: 12px;
        /* border: 1px solid #ddd; */
        overflow: hidden;
    }

    .Header {
        background: #3c8dbc;
        color: #fff;
        padding: 0 16px;
        padding-left: 10px;
        height: 50px;
        font-weight: 300;
        display: flex;
        align-items: center;
    }

    .Header p {
        flex-grow: 1;
        margin-left: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .Header .toggle {
        height: 25px;
        width: 25px;
        /* padding: 5px; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        transition: 0.2s;
        cursor: pointer;
        text-decoration: none;
        padding: 0px;
        margin: 5px;
    }

    .Header .toggle:hover {
        padding: 5px;
        margin: unset;
        background: rgba(255, 255, 255, 0.2);
    }

    .Header .toggle:active:hover {
        background: rgba(255, 255, 255, 0.1);
        padding: 1px;
        margin: 4px;
    }

    .Header.normalPadding {
        margin-top: 20px;
        padding: 8px;
    }
}
