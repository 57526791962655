.data-table-container-rawdata{
	width: 95%;
	margin: 5px auto;
}

.data-table-container-rawdata h1{
	color: rgb(60, 141, 188);
	font-size: 1rem;
}

.data-table-rawdata {
	width: 100%;
	min-height:10px;
	border-collapse: collapse;
	border-radius: 10px;
	white-space: nowrap;
	box-shadow: 0px 10px 10px #ccc;
	overflow: hidden;
}

.data-table-rawdata thead {
	background-color: rgb(60, 141, 188);
	color: rgb(255, 255, 255);
	text-align: left;
}

.data-table-rawdata th,
.data-table-rawdata td {
	padding: 0.8rem;
	min-width: 0;
	overflow: hidden;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	color: #000000;
	word-break: break-word;
}

.data-table-rawdata th {
	color: rgb(255, 255, 255);
}

.data-table-rawdata td {
	border-top: 0.5px solid #ddd;
	white-space: normal;
}

.data-table-rawdata tbody tr:hover {
	background-color: rgb(237, 237, 237);
}

.action-icons>i {
	color: white;
	margin-left: 5px;
}

.data-table-rawdata tbody tr:hover .action-icons>i {
	color: grey;
}

.verticalform {
  display: flex;
  flex-direction: row;
}

.verticalform textarea {
	flex-grow: 1; 
  width: 100%; 
  height: 80vh; 
	margin: 10px;
	border-color: black;
}

.query-container {
	margin-left: 10px;
}

@media (max-width: 1800px) {
	.data-table-container-rawdata{
		width: 80%;
		margin: 5px auto;
		margin-left: 150px;
	}
	
	.data-table-container-rawdata h1{
		color: rgb(60, 141, 188);
		font-size: 1rem;
	}
	
	.data-table-rawdata {
		width: 100%;
		border-collapse: collapse;
		border-radius: 10px;
		white-space: nowrap;
		box-shadow: 0px 10px 10px #ccc;
		overflow: hidden;
	}
	
	.data-table-rawdata thead {
		background-color: rgb(60, 141, 188);
		color: rgb(255, 255, 255);
		text-align: left;
	}
	
	.data-table-rawdata th,
	.data-table-rawdata td {
		padding: 0.8rem;
		min-width: 0;
		overflow: hidden;
		font-family: Arial, Helvetica, sans-serif;
		font-size: 12px;
		color: #000000;
		word-break: break-word;
	}
	
	.data-table-rawdata th {
		color: rgb(255, 255, 255);
	}
	
	.data-table-rawdata td {
		border-top: 0.5px solid #ddd;
		white-space: normal;
	}
	
	.data-table-rawdata tbody tr:hover {
		background-color: rgb(237, 237, 237);
	}
	
	.action-icons>i {
		color: white;
		margin-left: 5px;
	}
	
	.data-table-rawdata tbody tr:hover .action-icons>i {
		color: grey;
	}

	.query-container {
		margin-left: 60px;
	}
	
}

@media (max-width: 1400px) {
	.data-table-container-rawdata{
		width: 80%;
		margin: 5px auto;
		margin-left: 150px;
	}
	
	.data-table-container-rawdata h1{
		color: rgb(60, 141, 188);
		font-size: 1rem;
	}
	
	.data-table-rawdata {
		width: 100%;
		border-collapse: collapse;
		border-radius: 10px;
		white-space: nowrap;
		box-shadow: 0px 10px 10px #ccc;
		overflow: hidden;
	}
	
	.data-table-rawdata thead {
		background-color: rgb(60, 141, 188);
		color: rgb(255, 255, 255);
		text-align: left;
	}
	
	.data-table-rawdata th,
	.data-table-rawdata td {
		padding: 0.8rem;
		min-width: 0;
		overflow: hidden;
		font-family: Arial, Helvetica, sans-serif;
		font-size: 12px;
		color: #000000;
		word-break: break-word;
	}
	
	.data-table-rawdata th {
		color: rgb(255, 255, 255);
	}
	
	.data-table-rawdata td {
		border-top: 0.5px solid #ddd;
		white-space: normal;
	}
	
	.data-table-rawdata tbody tr:hover {
		background-color: rgb(237, 237, 237);
	}
	
	.action-icons>i {
		color: white;
		margin-left: 5px;
	}
	
	.data-table-rawdata tbody tr:hover .action-icons>i {
		color: grey;
	}

	.query-container {
		margin-left: 70px;
	}
}

@media (max-width: 900px) {
	.data-table-container-rawdata{
		width: 95%;
		margin: 5px auto;
		margin-left: 10px;
		overflow-x: auto;
	}
	
	.data-table-container-rawdata h1{
		color: rgb(60, 141, 188);
		font-size: 1rem;
	}
	
	.data-table-rawdata {
		width: 100%;
		border-collapse: collapse;
		border-radius: 10px;
		white-space: nowrap;
		box-shadow: 0px 10px 10px #ccc;
		overflow: hidden;
	}
	
	.data-table-rawdata thead {
		background-color: rgb(60, 141, 188);
		color: rgb(255, 255, 255);
		text-align: left;
	}
	
	.data-table-rawdata th,
	.data-table-rawdata td {
		padding: 0.8rem;
		min-width: 0;
		overflow: hidden;
		font-family: Arial, Helvetica, sans-serif;
		font-size: 12px;
		color: #000000;
		word-break: break-word;
	}
	
	.data-table-rawdata th {
		color: rgb(255, 255, 255);
	}
	
	.data-table-rawdata td {
		border-top: 0.5px solid #ddd;
		white-space: nowrap;
	}
	
	.data-table-rawdata tbody tr:hover {
		background-color: rgb(237, 237, 237);
	}
	
	.action-icons>i {
		color: white;
		margin-left: 5px;
	}
	
	.data-table-rawdata tbody tr:hover .action-icons>i {
		color: grey;
	}

	.query-container {
		margin-left: 0px;
	}

	.verticalform textarea {
		flex-grow: 1; 
	  width: 100%; 
	  height: 40vh; 
		margin: 10px;
		border-color: black;
	}
	
}