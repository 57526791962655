.datausage {
  margin: 30px;
  margin-top: 50px;
  margin-left: 40px;
}

.statics {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 20px;
}


.label {
  font-size: 16px;
}

.value {
  font-size: 14px;
  font-weight: bold;
}

.statics > div > div {
  margin-top: 5px;
}

.previous-month {
  
  margin-right: 150px;
}


@media (max-width: 1800px) {
  .datausage {
    margin: 30px;
    margin-top: 50px;
    margin-left: 80px;
    width: 90%;
  }
}

@media (max-width: 1480px) {
  .datausage {
    margin: 30px;
    margin-top: 50px;
    margin-left: 110px;
    width: 90%;
  }
}

@media (max-width: 1200px) {
  .datausage {
    margin: 30px;
    margin-top: 50px;
    margin-left: 150px;
    width: 80%;
  }
}

@media (max-width: 900px) {
  .datausage {
    margin: 30px;
    margin-top: 50px;
    margin-left: 0px;
    width: 100%;
  }

  .datausage-date {
    margin-left: 10px;
  }

  .statics {
    display: block;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 20px;
  }
  
}