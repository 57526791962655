.left {
  float: left;
}

.right {
  float: right;
}

.half {
  width: 49%;
  margin: 0;
}

.one-third {
  width: 33%;
}

.two-third {
  width: 66%;
}

.full-height {
  height: 100vh;
}

.full-width {
  width: 100%;
}

.align-v {
  margin-top: auto;
  margin-bottom: auto;
}

.three-fourth {
  width: 74%;
}

.one-fourth {
  width: 24%
}

.five-eighth {
  width: 61.5%;
}

.three-eighth {
  width: 36.5%
}

.align-right {
  text-align: right;
}

.hidden {
  display: none;
}

.inline-it {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-gap: 2px;
  background-color: #fff;
  color: #444;
  margin-right: 20px;
}

.grid-item {
  background-color: #444;
  color: rgb(119, 102, 102);
  /* border-radius: 5px;
  padding: 20px;
  font-size: 150%; */
  height: 150px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.grid-item>img {
  left: 50%;
  margin-left: -200px;
  position: absolute;
}

.flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.space-evenly {
  justify-content: space-evenly;
}

.flex-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.inline-grid {
  display: flex;
  flex-flow: row wrap;
  gap: 12px;
  margin-left: 30px;
  /* margin-bottom: 10px; */
  /* align-items: center; */
}

.inline-grid-reverse {
  display: flex;
  flex-flow: row wrap-reverse;
  margin-bottom: 10px;
  gap: 12px;
  /* align-items: center; */
}

.roundContainer {
  position: relative;
  top: -2px;
  font-size: 8px;
  background: #408eba;
  color: #fff;
  padding: 2px 5px;
  border-radius: 8px;
  display: inline-block;
  text-align: center;
}

.outer {
  position: relative;
}

.inner {
  overflow-x: scroll;
  overflow-y: visible;
  margin-left: 130px;
}

.overflow-x-scroll {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.pointer {
  cursor: pointer;
}

.v-center {
  align-items: center;
}

.fit-content {
  width: fit-content;
}

.container {
  padding: 10px;
  margin: 20px 10px;
}

.bordered {
  border: 1px solid #ddd;
  border-radius: 12px;
  border-collapse: unset;
}

.delete-number {
  margin-left: 30px;
}

@media (max-width: 1800px) {
  .inline-grid {
    display: flex;
    flex-flow: row wrap;
    gap: 12px;
    margin-left: 80px;
  }
  .delete-number {
    margin-left: 80px;
  }
}


@media (max-width: 1600px) {
  .inline-grid {
    display: flex;
    flex-flow: row wrap;
    gap: 12px;
    margin-left: 100px;
    /* margin-bottom: 10px; */
    /* align-items: center; */
  }
  .delete-number {
    margin-left: 100px;
  }
}

@media (max-width: 1250px) {
  .inline-grid {
    display: flex;
    flex-flow: row wrap;
    gap: 12px;
    margin-left: 150px;
    /* margin-bottom: 10px; */
    /* align-items: center; */
  }
  .delete-number {
    margin-left: 150px;
  }
}

@media (max-width: 900px) {
  .inline-grid {
    display: flex;
    flex-flow: row wrap;
    gap: 12px;
    margin-left: 10px;
    /* margin-bottom: 10px; */
    /* align-items: center; */
  }
  .delete-number {
    margin-left: 10px;
  }
}