/* src/styles.css */
/* @import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
 */

.formik-container{
	width: 80%;
	margin: 0px auto;
}

.formik-form {
  display: flex;
  flex-direction: column;
  width: 20%;
}

.form-group {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.form-group label {
  margin-right: 10px;
}

/* .form-group input {
  flex: 1;
} */