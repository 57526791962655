/*.TabBar {*/
/*background: #f8f8f8;*/
/*margin: 0;*/
/*height: 33px;*/
/*text-align: left;*/
/*}*/
/*.TabBar ul {*/
/*margin: 0;*/
/*padding: 0;*/
/*}*/
/*.TabBar li {*/
/*list-style: none;*/
/*display: inline-block;*/
/*margin: 0;*/
/*padding: 0;*/
/*height: 34px;*/
/*}*/
/*.TabBar li a {*/
/*margin: 0;*/
/*text-decoration: none;*/
/*color: #777;*/
/*background: #f8f8f8;*/
/*display: inline-block;*/
/*padding: 10px;*/
/*text-transform: uppercase;*/
/*font-size: 12px;*/
/*font-weight: bold;*/
/*}*/
/*.TabBar li a:hover {*/
/*color: #333333;*/
/*}*/
/*.TabBar .active {*/
/*background: #e7e7e7;*/
/*color: #555555;*/
/*}*/
.TabOptions {
  background: #e7e7e7;
  height: 34px;
}

/* .TabBar {
  overflow: hidden;
  margin: -1px;
} */

/*.TabBar ul {*/
/*display: flex;*/
/*flex-direction: row;*/
/*list-style: none;*/
/*padding: 0;*/
/*margin: 0;*/
/*}*/

.TabBar ul {
  margin: 0;
  padding: 0;
}

.TabBar li {
  display: inline-flex;
  align-items: center;
  list-style: none;
  /* margin: 1px 0 3px 0; */
  padding: 0;
  height: 38px;
  cursor: pointer;
}

.TabBar ul>li>a {
  /* box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-bottom: 1px solid #ddd; */
  position: relative;
  padding: 8px 12px;
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;
}

.TabBar ul>li:hover {
  text-decoration: none;
  background-color: rgba(60, 141, 188, 0.1);
}


.TabBar ul>li>a:hover {
  text-decoration: none;
}

.TabBar ul>li.active {
  background-color: rgba(51, 122, 183, 0.1);
  /* border: 1px solid #ddd;
  border-bottom: unset; */
}

.TabBar ul>li.active>a,
.TabBar ul>li.active>a:focus,
.TabBar ul>li.active>a:hover {
  cursor: default;
  color: #337ab7;
  text-decoration: none;
}

.TabBar ul>li>a {
  font-size: 13px;
  /* margin-right: 2px; */
  line-height: 1.5;
  position: relative;
  display: block;
  color: #777;
  text-decoration: none;
  font-weight: bold;
}

.TabBarRounded {
  border-radius: 18px;
  overflow: hidden;
  width: fit-content;
  border: 1px solid #ddd;
  margin-bottom: 5px;
}

.TabBarRounded ul {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.TabBarRounded li {
  position: relative;
}

.TabBarRounded li:after {
  /* border-right: 1px solid #ddd; */
  content: "";
  background: #ddd;
  position: absolute;
  margin: auto;
  right: 0;
  height: 60%;
  width: 1px;
}

.TabBarRounded li:last-child:after {
  display: none;
}


@media (max-width: 900px) {
  .TabBar {
      margin-bottom: 5px;
  }

  .TabBar ul {
      display: flex;
      flex-direction: row;
      /* flex-flow: row nowrap; */
      overflow-x: auto;
  }
}