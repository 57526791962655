.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(230, 231, 233);
}

.Date {
  margin-top: 50px;
}

.Date label {
  font-size: 1rem;
}

.form-1 {
padding-right: 100px;
width: 100%;
}

.time-1 {
margin-right: 100px;
}

.Form-0 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 300px;
}

.Form-0 input,
.Form-0 button {
  font-size: 0.8em;
}


.Date {
  padding-left: 160px;
}

@media (max-width: 1800px) {
  .header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgb(230, 231, 233);
  }
  
  .Date {
    margin-top: 50px;
  }
  
  .Date label {
    font-size: 1rem;
  }
  
  .form-1 {
  padding-left: 150px;
  }
  
  .time-1 {
  margin-right: 100px;
  }
  
  .Form-0 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .Form-0 input,
  .Form-0 button {
    font-size: 0.8em;
  }
  
  .Date {
    padding-left: 140px;
  }
}

@media (max-width: 1400px) {
  .header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgb(230, 231, 233);
  }
  
  .Date {
    margin-top: 50px;
  }
  
  .Date label {
    font-size: 1rem;
  }
  
  .form-1 {
  padding-left: 150px;
  }
  
  .time-1 {
  margin-right: 100px;
  }
  
  .Form-0 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 100px;
  }
  
  .Form-0 input,
  .Form-0 button {
    font-size: 0.8em;
  }
  
  .Date {
    padding-left: 140px;
  }
}

@media (max-width: 900px) {
  .header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgb(230, 231, 233);
  }
  
  .Date {
    margin-top: 50px;
    padding-left: 10px;
  }
  
  .Date label {
    font-size: 0.8rem;
  }
  
  .form-1 {
  padding-left: 85px;
  }
  
  .time-1 {
  margin-right: 100px;
  }
  
  .Form-0 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-left: 0px;
  }
  
  .Form-0 input,
  .Form-0 button {
    font-size: 0.6em;
    
  }
}