@import url(../node_modules/font-awesome/css/font-awesome.css);
@import url(./css/layout.css);
@import url(./css/modal.css);
@import url(./css/form.css);

.option-table-options {
  display: flex;
  border: 2px solid #ddd;
  border-radius: 15px;
  margin: 5px;
}

.option-table-options p {
  margin: 10px;
  text-decoration: underline;
  color: gray;
  cursor: pointer;
}

.option-table-data {
  border: 2px solid #ddd;
  border-radius: 10px;
  margin: 5px;
}

.option-table-data * p {
  margin: 10px;
  text-decoration: underline;
  color: gray;
  cursor: pointer;
}

.circle-shape {
  clip-path: circle(60px);
}


.hovericon {
  cursor: pointer;
}

.hovericon:hover {
  color: #3c8dbc;
}

.sidebar {
  position: fixed;
  width: 260px;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  background: #222d32;
  color: #b8c7ce;
  font-size: 14px;
  transition: 0.5s;
  overflow-x: hidden;
}

.main-section {
  margin-left: 260px;
  height: 100vh;
  transition: margin-left 0.5s;
}

.toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
  right: 12px;
  bottom: 12px;
}


.login-form,
.Form {
  display: flex;
  flex-direction: column;
  /* width: 500px; */
  /* margin: 20px; */
}

.Form>input,
select,
option,
.form-input {
  padding: 10px;
  min-width: 300px;
  margin-left: 10px;
  outline: none;
  border: 1px solid gray;
}

.form-input {
  text-decoration: underline gray;
}

.form-input>label {
  padding-right: 25px;
}

.Form>label {
  font-weight: bold;
  padding-top: 20px;
  margin: 5px;
}

.submit-btn {
  padding: 10px;
  margin: 10px;
  background-color: #3c8dbc;
  border: none;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
}

.addDevice {
  display: flex;
  justify-content: flex-end;
}

.addDevice button {
  background-color: coral;
}

.addServerOptions {
  font-size: 0.8em;
  display: flex;
}

.saveAndcancel  {  
  display: flex;
  justify-content:space-between;
}

.media-table {
  width: 100%;
  border-collapse: collapse;
}

.media-thead {
  background-color: #1976d2;
  color: white;
}

.center-div {
  display: grid;
  justify-self: center;
}

button {
  padding: 7px;
  border-radius: 5px;
  border: none;
  background: #1976d2;
  color: antiquewhite;
  margin: 5px;
  cursor: pointer;
  font-size: 10px;
}

.formInput {
  margin-left: 10px;
}

.formInput label {
  display: inline-block;
  text-align: left;
  width: 7vw;
  min-width: 4vw;
  margin: auto 10px auto 0;
  font-weight: bold;
  font-size: 12px;
}

.formInput input {
  font-size: 12px;
  padding: 10px;
  min-width: 12.5vw;
  /* border: 1px solid #ddd; */
  border-radius: 6px;
  border: 1px solid gray;
  color: #5d5d5d;
  transition: 0.1s;
}

.logo {
 width: 50px;
 height: 50px;
 margin: 20px;
}

.static-navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 40px;
  padding-top: 20px;
}

@media (max-width: 900px) {
  .formInput {
    display: flex;
    justify-content: flex-start;
    margin-left: 15px; 
  }
  


  .formInput label {
    /* display: inline-block; */
    text-align: left;
    width: 100vw;
    max-width:20vw;
    margin: auto 10px auto 0;
    font-weight: bold;
    font-size: 12px;
  }
  
}