.ServerModal {
  /* overflow: hidden; */
  /* border: 1px solid #ddd; */
  border-radius: 12px;
}
.ServerModal .modalHeader {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #3c8dbc;
  padding: 10px 16px;
  height: 24px;
  color: #fff;
}
.ServerModal .modalHeaderTitle {
  float: left;
}
.ServerModal .modalHeaderClose {
  float: right;
  cursor: pointer;
}
.ServerModal .modalHeaderClose .fa {
  color: #fff;
}
.ServerModal .modalChildren {
  padding: 1px 0px;
  /* padding-top: 20px; */
  max-height: 70vh;
  max-width: 70vw;
  min-width: 20vw;
  overflow-y: auto;
}

.ReactModal__Overlay {
  z-index: 2;
}