div.ReactTags__tags {
  position: relative;
  margin-right: 200px;
}
div.ReactTags__selected {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding-bottom: 10px;
  gap: 5px;
}
/* Styles for the input */
div.ReactTags__tagInput {
  width: 200px;
  display: inline-block;
}
div.ReactTags__tagInput input, div.ReactTags__tagInput input:focus {
  height: 31px;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 0 10px;
}
/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 12px;
  display: inline-block;
  padding: 5px 8px;
  /* margin: 0 5px; */
  cursor: move;
  border-radius: 12px;
}
div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  /* margin-left: 5px; */
  cursor: pointer;
}

button.ReactTags__remove {
  color: #aaa;
  cursor: pointer;
  max-height: 0;
  max-width: 0;
  margin: 0;
  margin-left: 5px;
  padding: 0 5px;
  background: none;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0,0,0,0.2);
  background: #fff;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.active {
  background: #b7cfe0;
  cursor: pointer;
}

.tags {
  position: relative;
  z-index: 1;
  margin-left: 10px;
}

@media (max-width: 1500px) {
  .tags {
    position: relative;
    z-index: 1;
    margin-left: 50px;
  }
  
}
