/* Dropdown Button */
.dropbtn {
  color: white;
  /*font-size: 16px;*/
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  border-radius: 6px;
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.userName {
  color: black !important;
  padding: 12px 16px;
  text-decoration: none !important;
  display: block;
}


.dropdown-content-left {
  margin-left: -182px;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black !important;
  padding: 12px 16px;
  text-decoration: none !important;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}

/*!* The container <div> - needed to position the dropdown content *!*/
/*.dropdown {*/
/*position: relative;*/
/*display: inline-block;*/
/*width: fit-content;*/
/*font-size: 14px;*/
/*}*/

/*!* Dropdown Content (Hidden by Default) *!*/
/*.dropdown-content {*/
/*display: none;*/
/*position: absolute;*/
/*background-color: #f1f1f1;*/
/*min-width: 200px;*/
/*box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);*/
/*z-index: 1;*/
/*right: 0;*/
/*}*/

/* Links inside the dropdown */
/*.dropdown-content a {*/
  /*color: black;*/
  /*padding: 12px 16px;*/
  /*text-decoration: none;*/
  /*display: block;*/
/*}*/

/*!* Change color of dropdown links on hover *!*/
/*.dropdown-content a:hover {*/
  /*background-color: #ddd*/
/*}*/

/*!* Show the dropdown menu on hover *!*/
/*.dropdown:hover .dropdown-content {*/
  /*display: block;*/
/*}*/