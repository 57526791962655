.Modal {
  /* overflow: hidden; */
  /* border: 1px solid #ddd; */
  border-radius: 12px;
}
.Modal .modalHeader {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #3c8dbc;
  padding: 10px 16px;
  /* height: 24px; */
  color: #fff;
}
.Modal .modalHeaderTitle {
  float: left;
}
.Modal .modalHeaderClose {
  float: right;
  cursor: pointer;
}
.Modal .modalHeaderClose .fa {
  color: #fff;
}
.Modal .modalChildren {
  padding: 5px 30px;
  /* padding-top: 20px; */
  max-height: 85vh;
  max-width: 85vw;
  min-width: 30vw;
  overflow-y: auto;
}

.ReactModal__Overlay {
  z-index: 2;
}