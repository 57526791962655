.details-container {
  width: 80%;
  height: 500px;
  margin-left: 150px;
  margin-top: 100px;
  background-color: lightblue;
  text-align: left;
  line-height: 50px; 
  border-radius: 10px; 
  box-shadow: 0px 10px 10px #ccc;
}
.details-header {
  background-color: #277dbd;
  padding: 10px;
}
.box {
  background-color: white;
  height: 450px
}

.details-navbar {
  background-color: #ffffff;
  padding: 5px;
}

.grid-container {
  display: flex;
  padding-left: 10px;
}

.grid-item {
  flex: 1;
  width: 100%;
  height: 390px;
  background-color: #ffffff;
  border: 1px solid #ddd; 
  margin: 10px;
  padding: 20px;
  box-sizing: border-box;
}

.grid-item p {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #ddd
}