.Modal2 {
  /* overflow: hidden; */
  /* border: 1px solid #ddd; */
  border-radius: 12px;
}

.refTime {
margin-left: 200px;
}

.Modal2 .modalHeader2 {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #3c8dbc;
  padding: 10px 16px;
  height: 24px;
  color: #fff;
  min-height: 40px;
}
.Modal2 .modalHeaderTitle2 {
  float: left;
}
.Modal2 .modalHeaderClose2 {
  float: right;
  cursor: pointer;
}
.Modal2 .modalHeaderClose2 .fa {
  color: #fff;
}
.Modal2 .modalChildren2 {
  padding: 5px 30px;
  /* padding-top: 20px; */
  max-height: 85vh;
  min-height: 80vh;
  max-width: 95vw;
  min-width: 70vw;
  overflow-y: auto;
}

.ReactModal__Overlay {
  z-index: 2;
}

@media (max-width: 900px) {
  .Modal2 .modalChildren2 {
    padding: 5px 30px;
    /* padding-top: 20px; */
    max-height: 85vh;
    min-height: 40vh;
    max-width: 95vw;
    min-width: 70vw;
    overflow-y: auto;
  }
  .refTime {
    margin-left: 20px;
    }
}